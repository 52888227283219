import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/lafiaccola',
    name: 'LaFiaccola',
    component: () => import('../views/LaFiaccola.vue')
  },
  {
    path: '/news',
    name: 'LeNostreNews',
    component: () => import('../views/LeNostreNews.vue')
  },
  {
    path: '/lafiaccola-news',
    name: 'LeNostreNewsLaFiaccola',
    component: () => import('../views/LeNostreNewsLaFiaccola.vue')
  },
]

const router = new VueRouter({
 
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }

      return { selector: to.hash }
    }
  },
})

export default router
