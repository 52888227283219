<template>
  <div id="home">
    <Header class="orange-bg" />
    <section class="homepage orange-bg">
      <div class="home-title">
        <h1>
          <span class="font-sx">SICILIA</span
          ><span class="font-dx">PUNTOL</span>
        </h1>
        <div class="text-cnt">
          <div class="desc" :class="{ expanded: isShowMoreClicked }">
            <p class="text">
              Le edizioni “Sicilia Punto L” nascono nel 1980 con l’intenzione di
              aprire un fronte politico-culturale siciliano e meridionale,
              producendo e pubblicando testi dall’orizzonte specifico
              <span v-show="!isShowMoreClicked">...</span>
              <span v-show="isShowMoreClicked" class="text-span">
                , al fine di dotare l’anarchismo di una politica meridionalista,
                etnica, nazionalitaria, internazionalista, e per scoprire,
                stimolare ed offrire ad un pubblico più vasto quello che è stato
                ed è il contributo degli anarchici alla ricerca teorica, storica
                e culturale avente come riferimento la Sicilia. Caso quasi unico
                di editrice “locale”, “Sicilia Punto L” ha contribuito a sfatare
                molti “tabù”, a partire da quello dell’omogeneità
                politico-sociale-culturale del contesto italiano, aprendo,
                attraverso le sue collane “storia/interventi”, “etnia:
                saggi/testi”, “letteratura libertaria”, “nuovo teatro” e “i
                lapilli”, nuove frontiere al pensiero libertario.
              </span>
            </p>
            <button class="button-border leggi" @click="showMore">
              {{ isShowMoreClicked ? "LEGGI MENO" : "LEGGI PIÙ" }}
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L5 5L1 9" stroke="#FFFFEC" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>

    <Gallery />
    <EventiNews />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Gallery from "@/components/Gallery.vue";
import EventiNews from "@/components/EventiNews.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Gallery,
    EventiNews,
    Footer,
  },

  data() {
    return {
      isShowMoreClicked: false,
    };
  },

  methods: {
    showMore() {
      this.isShowMoreClicked = !this.isShowMoreClicked;

      let textSpan = document.querySelector(".text-span");
      textSpan.classList.toggle("show");
    },

  },
}

</script>

<style>
</style>
