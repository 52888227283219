<template>
  <footer class="mx-50">
    <div class="container-fluid">
      <div class="row p-0">
        <div class="col-sm-12 col-lg-4 p-0">
          <h3>SICILIA<span>PUNTOL</span></h3>
          <p class="foot-p">ASSOCIAZIONE CULTURALE</p>
        </div>
        <div class="col-2 links">
          <a href="#about">ABOUT</a>
          <a href="#gallery">GALLERY</a>
          <a href="#news">NEWS</a>
        </div>
        <div class="col contacts">
          <p>CONTATTACI</p>
          <p>
            Richieste, pagamenti, prenotazioni e contributi vanno indirizzati a:

            <span
              >ASSOCIAZIONE CULTURALE SICILIA PUNTO L Via Garibaldi 2 A - 97100
              Ragusa</span
            ><br /><br />
            CONTO CORRENTE POSTALE n.1025557768<br />
            IBAN: IT 90 O 07601 17000 001025557768<br />
            CODICE BIC/SWIFT: BPPIITRRXXX<br /><br />
            Per richieste uguali o superiori alle 5 copie si applica lo sconto
            del 40%.<br /><br />
            Per qualsiasi esigenza scrivere a:
            <a href="mailto:info@sicilialibertaria.it"
              >info@sicilialibertaria.it</a
            >
          </p>
        </div>

        <div class="col-1 text-end p-0">
          <div class="social">
            <!-- FACEBOOK -->
            <a
              href="https://it-it.facebook.com/sicilia.libertaria/"
              target="_blank"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z"
                  fill="black"
                />
              </svg>
            </a>

            <!-- TELEGRAM -->
            <a href="https://t.me/sicilialibertaria" target="_blank">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14.64 6.8C14.49 8.38 13.84 12.22 13.51 13.99C13.37 14.74 13.09 14.99 12.83 15.02C12.25 15.07 11.81 14.64 11.25 14.27C10.37 13.69 9.87 13.33 9.02 12.77C8.03 12.12 8.67 11.76 9.24 11.18C9.39 11.03 11.95 8.7 12 8.49C12.0069 8.45819 12.006 8.42517 11.9973 8.3938C11.9886 8.36244 11.9724 8.33367 11.95 8.31C11.89 8.26 11.81 8.28 11.74 8.29C11.65 8.31 10.25 9.24 7.52 11.08C7.12 11.35 6.76 11.49 6.44 11.48C6.08 11.47 5.4 11.28 4.89 11.11C4.26 10.91 3.77 10.8 3.81 10.45C3.83 10.27 4.08 10.09 4.55 9.9C7.47 8.63 9.41 7.79 10.38 7.39C13.16 6.23 13.73 6.03 14.11 6.03C14.19 6.03 14.38 6.05 14.5 6.15C14.6 6.23 14.63 6.34 14.64 6.42C14.63 6.48 14.65 6.66 14.64 6.8Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <!-- COPYRIGHT -->
      <div class="row copy">
        <div class="col-12 yomi text-center">
          <p>
            Made w/ love by
            <a href="https://yomi.digital/" ref="yomi" target="_blank">YOMI</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>