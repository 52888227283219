<template>
  <section id="news" class="news purple-bg">
    <div class="container-fluid">
      <div class="row w-100 mx-50">
        <h1 class="section-title">
          NEWS E<br />
          APPUNTAMENTI
        </h1>
        <div class="col-sm-0 col-lg-3 p-0"></div>
        <div class="col-sm-12 col-lg-9 p-0">
          <carousel
            :per-page="1"
            :autoplayTimeout="5000"
            :autoplay="true"
            :loop="true"
            @input="updateCurrentSlideIndex"
          >
            <slide v-for="(evento, index) in eventiRecenti" :key="index">
              <div class="slide-cnt">
                <div class="photo-cnt">
                  <img
                    :src="`https://siciliapuntol.it/contents/${evento.photo}`"
                    alt="cover news"
                    draggable="false"
                  />
                </div>
                <div class="slide-desc">
                  <div>
                    <p class="news-subtitle" v-if="evento.sottotitolo">
                      {{ evento.sottotitolo }}
                    </p>
                    <h2 class="news-title">
                      {{ evento.titolo }}
                    </h2>
                    <a :href="'/#/news?id=' + evento.id">
                      <button class="button-border">
                        VAI
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 1L5 5L1 9" stroke="white" />
                        </svg>
                      </button>
                    </a>
                  </div>

                  <p
                    class="news-desc"
                    v-html="evento.descrizione.substring(0, 255) + '...'"
                  ></p>
                </div>
              </div>
            </slide>
            <div slot="pagination" class="pagination-carousel">
              {{ currentSlideIndex + 1 }}/{{ eventiRecenti.length }}
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "EventiNews",

  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      eventiRecenti: [],
      currentSlideIndex: 0,
    };
  },

  methods: {
    updateCurrentSlideIndex(index) {
      this.currentSlideIndex = index;
    },
  },

  async mounted() {
    let eventi_data = await fetch(
      "https://www.siciliapuntol.it/api/eventi-recenti"
    );
    let eventiRecenti = await eventi_data.json();
    this.eventiRecenti = eventiRecenti;
  },
};
</script>

<style>
</style>